<template class="tinted-image">
  <div id="page-login">
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-justify="right" vs-align="center" vs-xs="12" vs-w="4">
        <p class="p-6 img-banner-home">
          <img src="images/recover-pass-550.png" />
        </p>
      </vs-col>
      <vs-col vs-justify="left" vs-align="center" vs-xs="12" vs-w="4">
        <!-- login -->
        <form method="POST">
          <div class="p-6 mt-12">
            <h1>Reiniciar contraseña</h1>

            <vs-input
              type="password"
              label-placeholder="Ingreso tu nueva contraseña (min: 4 caractéres)"
              v-model="password"
              color="warning"
              class="w-full mt-6 mb-4"
              v-validate="'required|min:4'"
              name="pass"
            />

            <vs-input
              type="password"
              label-placeholder="Confirma la nueva contraseña (min: 4 caractéres)"
              v-model="confirmPassword"
              color="warning"
              class="w-full mt-10 mb-4"
              v-validate="'required|min:4'"
              name="pass"
            />

            <vs-button
              class="w-full btn-black r-30 mb-2"
              :disabled="!blockLogin"
              @click="restorePassword"
              ref="loadableButton"
              id="button-with-loading"
              >Reiniciar contraseña
              <img src="images/siguiente.png" class="ml-3" width="50" />
            </vs-button>
          </div>
        </form>

        <vs-popup
          title=""
          :active.sync="success"
          class="alert-finish-process1"
          @close="handleClose"
        >
          <vs-divider position="center" class="m-0">
            <img
              src="images/recover-finish.png"
              alt="Logo Uncierre"
              width="150"
            />
          </vs-divider>
          <div class="alert-finish-process p-3">
            <p class="mb-3">
              Tu contraseña <br />
              ha sido reiniciada <br />
              con éxito
            </p>
            <span
              >Ahora puedes ingresar a UnCierre con tu nueva contraseña
            </span>
          </div>
        </vs-popup>

        <vs-divider position="center">
          <img src="images/logo-uncierre-514x114.png" alt="Logo Uncierre" />
        </vs-divider>
      </vs-col>
    </vs-row>

    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-justify="right" vs-align="center" vs-w="8">
        <ul class="list-uncierre text-right">
          <li>Administra,</li>
          <li>agiliza,</li>
          <li>y facilita</li>
          <li><strong>tus cierres.</strong></li>
        </ul>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { auth } from "../../../services";

export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      success: false,
    };
  },
  computed: {
    blockLogin() {
      return (
        this.password != "" &&
        this.confirmPassword != "" &&
        this.password === this.confirmPassword
      );
    },
  },
  methods: {
    async restorePassword() {
      try {
        if (!"token" in this.$route.query) {
          this.$vs.notify({
            title: "Error",
            text: "No se encontró token de autorización",
            color: "danger",
          });
          return;
        }

        const token = this.$route.query.token;
        await auth.resetPassword(this.password, token);
        this.success = true;
      } catch (e) {
        this.$vs.notify({
          title: "Error",
          text: "Ha ocurrido un error al actualizar la contraseña, por favor intenta más tarde",
          color: "danger",
        });
      }
    },
    handleClose() {
      this.success = false;
      this.$router.replace("/login");
    },
  },
};
</script>
<style lang="scss">
.alert-finish-process1 {
  text-align: center;
  .vs-popup {
    color: #fff;
    background: #000 !important;
  }
}

.alert-finish-process {
  > p {
    font-size: 30px;
    line-height: 32px;
    font-weight: bold;
  }
  span {
    display: block;
  }
}

#page-login {
  background-color: #fff;
  padding: 30px 0;

  .r-30 {
    border-radius: 30px;
  }

  .customLink {
    color: #000;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    display: block;
  }

  .customLink img {
    display: inline;
  }

  .btn-black {
    background: #000 !important;
  }

  button img {
    display: inline;
  }

  input {
    border-radius: 30px;
    background: #f2f4f6;
    color: #111 !important;
  }

  @media (min-width: 300px) and (max-width: 776px) {
    .img-banner-home {
      display: none;
    }
  }
}
</style>
